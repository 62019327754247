<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i>{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>


                <div class="row mb-3">

                    <div class="col">
                        <label class="mr-sm-6">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>

                    <div class="col">
                        <label class="mr-sm-6" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                    
                  
                </div>




                <div class="row mb-3">

                  
                    
                    <div class="col">
                        <label class="mr-sm-12">Operación</label>
                        <select class="custom-select mr-sm-2" id="operacion" name="operacion[]" multiple="multiple"></select>
                    </div>
                    
                  
                </div>

                 <div class="row mb-3">

                  
                 
                    
                    <div class="col" style="background-color: #dddddd;padding: 10px">
                        
                        <center>
                        <button  class="btn btn-md btn-danger w-40" @click="guardarFiltro()" style="padding: 10px;">Guardar filtro</button>
                        <span class="mr-sm-2 w-5"></span>
                        <button  class="btn btn-md btn-success w-40" @click="obtenerPersonal()" style="padding: 10px;">Consultar Consumos</button>
                        </center>
                    </div>
                </div>

                       <div class="row mb-3">

                  
                 
                    
                    <div class="col" style="background-color: #dddddd;padding: 10px">
                        <label class="mr-sm-12">Filtros guardados</label>
                        <div id="filtros"></div>
                    </div>
                </div>
          
          


                        </div>  
                      </div>
                    </div>
                  </div>    

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title" id="mestext"></h6>
                           <div id="alerta" class="alert alert-danger" role="alert">Para poder visualizar un reporte debera seleccionar un rango de fecha y una operación</div>


                            <div id="espera" class="alert alert-warning" role="alert">Espere un instante por favor!. Estamos cargando todos los datos solicitados. ESTA OPERACIÓN PUEDE TARDAR VARIOS SEGUNDOS</div>
                            <div id="progreso"></div>


                            <table width="100%" style="font-size: 26px; border: 1px solid rgb(215, 215, 215);" id="calculo">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div id="fecha" style="padding:10px;background-color:#f7f3f3;">
                                                Reporte: <span id="semana_numero">0</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div id="ingreso" style="text-align:center;background-color:#f8ffbc;padding:10px;">
                                                Total de consumos: <span id="pedidos_numero">0</span>
                                            </div>
                                        </td>
                                        <!--<td>
                                            <div id="egreso" style="text-align:center;background-color:#8f9d1961;padding:10px;">
                                                Total adicional: <span id="consolidado_numero">0</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div id="egreso" style="text-align:center;background-color:#8f9d1961;padding:10px;">
                                                Diferencia: <span id="diferencia_numero">0</span>
                                            </div>
                                        </td>-->
                                    </tr>
                                </tbody>
                            </table>
                          <br><br>
                          <div class="table-responsive">

                                <table id="consumos" class="table"></table>

                          </div>
                        
<!--
                        <br><br>
                          <span class="table-responsive"> <h5>Reporte de adicionales</h5> </span>
                          <hr>
                        <div class="table-responsive">

                                <table id="consolidado" class="table"></table>

                          </div>

-->

                        </div>
                      </div>
                    </div>
                  </div>


             

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
$.fn.dataTable.ext.errMode = 'none';
import select2 from 'select2';
import firebase from "firebase/app";
import "firebase/database";
 import Swal from 'sweetalert2';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));



export default {
    name: 'Personal',
    
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Consumos de Catering',
            descripcion: 'En esta seccion visualizara los consumos de las operaciones que tienen la modalidad de Catering',
            triggers: {load:true},
            semanas: [],
            
        }
    },
    methods: {
       
       guardarFiltro(){
             


                        
                       


                        /* GUARDAR FILTRO */

                            

                            (async function(){

                                    await Swal.fire({
                                            title: 'Escriba un nombre al filtro',
                                            html:
                                            '<form action="#" id="searchForm">'+
                                              '<input required type="text" id="filtro_name" class="swal2-input" placeholder="Nombre de filtro"  autocomplete="off"><button type="button" id="guardar" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;">Guardar</button></form>',
                                                   showCancelButton: false,   
                                                   showConfirmButton: false, 
                                                didOpen(){
                                                  window.$("#guardar").click(function(){
                                                     Swal.showLoading()
                                                     var nombre = $("#filtro_name").val();
                                                     var operacion = $("#operacion").val();

                                                     firebase.database().ref('facturacion_catering_filtro/').push({
                                                          nombre:nombre,
                                                          empresas:operacion

                                                        })
                                                        .then(()=>{     

                                                                Swal.close();

                                                                Swal.fire(
                                                                  "Filtro guardado!",
                                                                  "El filtro quedo guardado y listo para utilizar cuando prefiera",
                                                                  "success"
                                                                );
                                                        }).catch(error => {console.log(error);})

                                                    
                                                  })
                                               }
                                           


                                            })

                                    })();



                             
                          
                               
                       


                        /* CIERRA GUARDAR FILTRO */







       },
       
        obtenerPersonal(){
            
           

      
           var fechas = [];

           var desde = $("#desde").val();
           var hasta = $("#hasta").val();
           var operacion = $("#operacion").val();

          if (desde!=""&&hasta!=""&&operacion!="") {


             $("#espera").slideDown();
            $('#consumos_wrapper').empty();
            $('#consumos_wrapper').html('<table id="consumos" class="table"></table>');


            $('#consolidado_wrapper').empty();
            $('#consolidado_wrapper').html('<table id="consolidado" class="table"></table>');
            $("#calculo").slideUp();


           var inicio = desde;
           var fin = hasta;
           var dateOffset = (24*60*60*1000) * 1; //1 days

           var fechaInicio  = new Date(inicio);
           var fechaFin     = new Date(fin);

           fechaInicio.setTime(fechaInicio.getTime());
           fechaFin.setTime(fechaFin.getTime());

            while(fechaFin.getTime() >= fechaInicio.getTime()){
            fechaInicio.setDate(fechaInicio.getDate() + 1);

            fechas.push(("0" + (fechaInicio.getDate())).slice(-2)  + '-' + ("0" + (fechaInicio.getMonth() + 1)).slice(-2) + '-' + fechaInicio.getFullYear());
            }

               

        


            
                     $("#loading").hide();
                     $("#alerta").slideUp();

                     

            $("#semana_numero").html(desde+' hasta '+hasta)


        
                  


                                    var fechaInicio = new Date(desde).getTime();
                                    var fechaFin    = new Date(hasta).getTime();

                                    var diff = fechaFin - fechaInicio;

                                    var tiempo = diff/(1000*60*60*24)*2;



                                    var timeleft = tiempo;

                                  
                                    
                                    $("#progreso").html('<progress value="0" max="'+timeleft+'" id="progressBar" style="padding: 21px;width: 100%;"></progress>')

                                    var downloadTimer = setInterval(function(){
                                      if(timeleft <= 0){
                                        clearInterval(downloadTimer);
                                      }
                                      $("#progressBar").val(tiempo - timeleft);
                                      timeleft -= 1;
                                    }, 1000);


                                     var columna = []; 
                                                columna.push({"title":"ID"})
                                                columna.push({"title":"NOMBRE"})
                                                columna.push({"title":"IDENTIFICADOR"})
                                                columna.push({"title":"EMPRESA"})
                                                for (var f = 0; f < fechas.length; f++) {
                                                    columna.push({"title":fechas[f]})
                                                   
                                                }
                                                    var tabla = $('#consumos').DataTable({

                                                                columns: columna,



                                                            "language": {
                                                                "decimal": ",",
                                                                "thousands": ".",
                                                                "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                                "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                                "infoPostFix": "",
                                                                "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                                                "loadingRecords": "Cargando...",
                                                                "lengthMenu": "Mostrar _MENU_ registros",
                                                                "paginate": {
                                                                    "first": "Primero",
                                                                    "last": "Último",
                                                                    "next": "Siguiente",
                                                                    "previous": "Anterior"
                                                                },
                                                                "processing": "Procesando...",
                                                                "search": "Buscar:",
                                                                "searchPlaceholder": "Término de búsqueda",
                                                                "zeroRecords": "No se encontraron resultados",
                                                                "emptyTable": "NO SE REGISTRAN CONSUMOS PARA ESTA OPERACIÓN",
                                                                "aria": {
                                                                    "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                                                    "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                                                },
                                                            },
                                                            "scrollX": false, 
                                                            "search": {regex:true},
                                                          
                                                            dom: 'Bfrtip',
                                                            responsive: false,
                                                            nowrap: false,
                                                            buttons: [
                                                                'copy',
                                                                'excel',
                                                                'csv',
                                                                'pdf'
                                                            ]
                                                        });

                                            
                                           

                                          

                                                           var contador_pedidos = 0;
                                                           var pedidos_cat = [];

                                    for (var i = 0; i < operacion.length; i++) {
                                   


                                         /* CONSULTA DE CONSUMOS */

                                    $.ajax({
                                      url : 'https://auth.foodservice.com.ar/?type=consumos_agrupados_catering&desde='+desde+'&hasta='+hasta+'&op='+operacion[i]+'&access_token=1234567',
                                      type:'GET',
                                     success : function(json) {

                                                console.log(json)

                                                $(".table-responsive").css("visibility","visible");
                                                $("#espera").slideUp();
                                                $("#calculo").slideDown();
                                                 $("#progreso").html("");
                                                setTimeout(function(){
                                                    var cons = parseInt($("#consolidado_numero").html());
                                                    var pedi = parseInt($("#pedidos_numero").html());
                                                    $("#diferencia_numero").html(cons-pedi);

                                                },1000)

                                               

                                                            $.each(JSON.parse(json),function(clave,valor){
                                                               
                                                                var fechas = [];
                                                                fechas.push( valor.id,valor.nombre,valor.identificador,valor.empresa)
                                                                $.each(valor['fechas'],function(key,value){

                                                                    fechas.push(value.cantidad)
                                                                    pedidos_cat.push(parseInt(value.cantidad))

                                                                });

                                                                

                                                               
                                                                   
                                                                 tabla.row.add(fechas).draw();
                                                                
                                                                 contador_pedidos = pedidos_cat.reduce((a, b) => a + b, 0);

                                                                
                                                            });

                                                           

                                            
                                        }
                                     
                                    });

                                    /* CIERRA CONSULTA DE CONSUMOS */







                                    }



                                    setTimeout(function(){
                                        $("#pedidos_numero").html(contador_pedidos)
                                    },3000)

                          
                                }else{

                                     Swal.fire(
                                                                  "ERROR!",
                                                                  "No pueden existir campos vacios",
                                                                  "error"
                                                                );


                                }


                
        },


       
    },
   mounted() {


        $("#operacion").select2({
          placeholder: "Seleccione operaciones", 
          initSelection: true 
        });

    firebase.database().ref('facturacion_catering_filtro').on("value", function(snapshot) {
            $("#filtros").html("");
            var data = snapshot.val();
           if (data==null) {

                $("#filtros").html("<span style='color:gray;'>- NO EXISTEN FILTROS GUARDADOS -</span>")

           }else{

                $.each(data, function(clave, valor) {

                  $("#filtros").prepend(" <button class='filtro' data-filtro='"+valor.empresas.toString()+"'>"+valor.nombre+"</button>")
                   console.log(valor.nombre)



                });

           }
            

            
          
            $(".filtro").click(function(){

                var data = $(this).data("filtro");
                var data_array = data.split(",");

                 $("select").val(data_array).change();


            })

           

         



        });

                
       

       

              // CAPTURO OPERACIONES
                axios.get('https://consumos.foodservice.com.ar/api/empresas')
                      .then(function (response) {
                            
                            response.data.sort(function (a, b) {
                              if (a.name > b.name) {
                                return 1;
                              }
                              if (a.name < b.name) {
                                return -1;
                              }
                              // a must be equal to b
                              return 0;
                            });

                            $.each(response.data, function(clave, valor) {

                                if (valor.estado=="ACTIVA"&&valor.tipo_empresa=="CATERING") {
                                    
                                   
                                    $("#operacion").append('<option value="'+valor.id+'">'+valor.name+'</option>');
                                }
                               
                               //$("#operacion").prop("selectedIndex", 0);

                            })





                      })
                      .catch(function (error) {
                      
                        console.log(error);
                      });

        },
};
</script>

<style scoped>
.td {
    
    border: 1px solid #e6e6e6;
}


.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#loading{
 
  width: 100%;
}

.table-responsive{
  visibility: hidden;
}

.table thead th {

    color: #ffffff;
}

#espera{
 
  display: none;
}
#calculo{
 
  display: none;
}

progress::-moz-progress-bar { background: #031a61; }
progress::-webkit-progress-value { background: #031a61; }
progress { color: #031a61; }
</style>

