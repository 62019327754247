import { createWebHistory, createRouter } from "vue-router";


import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";


import Catering from "@/views/catering/Pago.vue";
import Echeck from "@/views/catering/echeck.vue";
import Salida from "@/views/salida/Pago.vue";
import Agrupados from "@/views/agrupados/Grupo.vue";
import AgrupadosCatering from "@/views/agrupadosCatering/Grupo.vue";
import Mixto from "@/views/mixto/Pago.vue";
import Congelados from "@/views/congelados/Pago.vue";
import Procesados from "@/views/catering/echeckProcesado.vue";


const routes = [
 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/login",
    name: "Login",
    component: Login,
  },
  
   {
    path: "/catering",
    name: "Catering",
    component: Catering,
  },
   {
    path: "/congelados",
    name: "Congelados",
    component: Congelados,
  },
   {
    path: "/mixto",
    name: "Mixto",
    component: Mixto,
  },

   {
    path: "/echeck",
    name: "Echeck",
    component: Echeck,
  },

  {
    path: "/echeckProcesado",
    name: "echeckProcesado",
    component: Procesados,
  },
  
   {
    path: "/salida",
    name: "Salida",
    component: Salida,
  },
  
   {
    path: "/agrupados",
    name: "Agrupados",
    component: Agrupados,
  },
  
   {
    path: "/agrupadoscatering",
    name: "AgrupadosCatering",
    component: AgrupadosCatering,
  }
 
 
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;