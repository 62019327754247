<template>
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
        FACTURACIÓN
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">
          
            <li class="nav-item nav-category">Consumos x empresa</li>
         
           <li class="nav-item" >
            <router-link :to="{ name: 'Catering'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Consumos Catering</span>
            </router-link>
          </li>

           <li class="nav-item" >
            <router-link :to="{ name: 'Salida'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Consumos Salida</span>
            </router-link>
          </li>

          <li class="nav-item nav-category">Consumos Agrupados</li>

           <li class="nav-item" >
            <router-link :to="{ name: 'AgrupadosCatering'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Agrupados Catering</span>
            </router-link>
          </li>

          <li class="nav-item" >
            <router-link :to="{ name: 'Agrupados'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Agrupados Salida</span>
            </router-link>
          </li>

          <li class="nav-item nav-category">MODALIDAD CON PAGO ONLINE</li>

           <li class="nav-item" >
            <router-link :to="{ name: 'Mixto'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Catering</span>
            </router-link>
          </li>

          <li class="nav-item nav-category">MODALIDAD CONGELADOS</li>

           <li class="nav-item" >
            <router-link :to="{ name: 'Congelados'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Eaty SKF</span>
            </router-link>
          </li>

           <li class="nav-item nav-category">BAS ECHECK's</li>

           <li class="nav-item" >
            <router-link :to="{ name: 'Echeck'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Cheques electronicos</span>
            </router-link>
          </li>

          <li class="nav-item" >
            <router-link :to="{ name: 'echeckProcesado'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Validacion Bancaria</span>
            </router-link>
          </li>

          

        


           
        
        </ul>
      </div>
    </nav>
</template>

<script>


const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
    methods:{
     isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      },
       mounted() {
       this.isLector('Facturacion');
    },
  }
};



</script>